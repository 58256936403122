let awsRegion = 'ap-southeast-2';

export default {
 api_urls: {
   reporting: 'https://qqw00occxk.execute-api.ap-southeast-2.amazonaws.com/api',
   geojson: 'https://ic9e4d62x8.execute-api.ap-southeast-2.amazonaws.com/api',
   overlays: 'https://vwkjjo4gc8.execute-api.ap-southeast-2.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 6,
 crm_base_url: 'https://crm.nsr.forwoodsafety.com',
 forwood_id_url: 'https://id.nsr.forwoodsafety.com?redirect_uri=https://geoeditor.nsr.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.nsr.forwoodsafety.com',
 Auth: {
   userPoolId: 'ap-southeast-2_6nWmGigyH',
   userPoolWebClientId: '1mpcnqjqg0f4kim25l39mpgnpd',
   cookieStorage: {
     domain: '.nsr.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'True',
 env: 'prod'
};
